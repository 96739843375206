import {
  AnyApiFactory,
  configApiRef,
  createApiFactory, gitlabAuthApiRef
} from '@backstage/core-plugin-api';
import {
  ScmAuth, scmAuthApiRef, ScmIntegrationsApi,
  scmIntegrationsApiRef
} from '@backstage/integration-react';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import TechRadarClient from './tech-radar/tech-radar-client';


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      gitlabAuthApi: gitlabAuthApiRef,
    },
    factory: ({ gitlabAuthApi }) => ScmAuth.forGitlab(gitlabAuthApi, {host: 'gitlab.sehlat.io'}),
  })
];
