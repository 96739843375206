import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#ffffff',
  },

  collapsed: {
    transform: 'translate(-3.6rem)'
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
      <svg
          className={classes.svg}
          xmlns="http://www.w3.org/2000/svg"
          width="100px" height="20px"
      >
        <g className={classes.path}>
          <path
                d="M0.246739514,0 L0.246739514,0.669721537 L1.65667959,0.669721537 L1.65667959,15.5093409 C1.58618259,17.4480085 1.33944307,18.6464575 0.317236517,18.963694 C0.281988016,18.963694 0,19.034191 0,19.034191 L0,19.6686641 C0,19.6686641 0.634473035,19.7039126 1.26894607,19.5629186 C3.13711667,19.1751851 4.15932323,17.765245 4.19457173,15.5798379 L4.19457173,0 L0.246739514,0 Z"
                id="Path" />

          <path className={classes.collapsed}
                d="M71.4134649,0 C72.8586535,0 73.9161086,0.317236517 74.5858301,0.986958054 C75.1498061,1.55093409 75.4317941,2.39689813 75.4317941,3.5600987 C75.4317941,5.3930208 74.3743391,6.55622136 72.259429,7.04970039 C74.4448361,7.3316884 76.5949947,8.10715545 76.5949947,11.3147691 C76.5949947,12.6542122 76.2777582,13.6411703 75.5727882,14.3108918 C74.7620726,15.1216073 73.4931265,15.4740924 71.5897074,15.4740924 L64.540007,15.4740924 L64.540007,14.8043708 L66.0204441,14.8043708 L66.0204441,0.669721537 L64.540007,0.669721537 L64.540007,0 L71.4134649,0 Z M70.9552344,7.43743391 L68.5935848,7.43743391 L68.5935848,14.8396193 L70.6027494,14.8396193 C73.4931265,14.8396193 73.9161086,12.9714487 73.9161086,11.1385266 C73.9161086,9.72858654 73.598872,8.77687698 72.9996475,8.14240395 C72.5061685,7.64892492 71.836447,7.43743391 70.9552344,7.43743391 Z M70.5322524,0.669721537 L68.5935848,0.669721537 L68.5935848,6.66196687 L70.3560099,6.66196687 C72.118435,6.66196687 72.752908,5.88649982 72.752908,3.84208671 C72.752908,2.99612266 72.6471625,1.8329221 72.0126895,1.19844907 C71.6602044,0.845964047 71.1667254,0.669721537 70.5322524,0.669721537 Z"
                id="Combined-Shape" />
        </g>
      </svg>
  );
};

export default LogoFull;
