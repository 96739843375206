import languagesFrameworks from './languages-&-frameworks.json';
import platformsServices from './platforms-&-tools.json';
import standardsGoodPractices from './standards-&-good-practices.json';
import toolsPackages from './packages-&-libraries.json';

const ENTRIES = [
    ...languagesFrameworks,
    ...platformsServices,
    ...standardsGoodPractices,
    ...toolsPackages
];

export default ENTRIES;