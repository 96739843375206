import {createUnifiedTheme, darkTheme, genPageTheme, shapes,} from '@backstage/theme';

export const BJBTheme = createUnifiedTheme({
    palette: {
        ...darkTheme.palette,
        navigation: {
            ...darkTheme.palette.navigation,
            indicator: '#ffffff'
        },
    },
    pageTheme: {
        home: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave}),
        documentation: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave2,}),
        tool: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.round}),
        service: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave,}),
        website: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave,}),
        library: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave,}),
        other: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave}),
        app: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave}),
        apis: genPageTheme({colors: ['#141e55', '#141e55'], shape: shapes.wave}),
    }
});