import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import {
    appRouteRef,
    calendarRouteRef,
    capacityRouteRef,
    itsoRouteRef,
    leaderBoardRouteRef,
    qualityRouteRef,
    releasesRouteRef,
    rootRouteRef,
    sprintReportRouteRef,
    squadStructureRouteRef,
    itsoAdminRouteRef,
    engageJourneyRouteRef,
    rocPlanRouteRef,
    onboardingRouteRef,
    piRouteRef,
    testingRouteRef,
    pipelinesRouteRef,
    genAIRouteRef,
    certificateRouteRef,
    aboutRouteRef,
    vulnerabilitiesRouteRef,
    rFCComponent
} from './routes';

export const jbJiraPlugin = createPlugin({
  id: 'jb-jira',
  routes: {
    root: rootRouteRef,
  }
});

export const SprintReportPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'SprintReportPage',
        component: () =>
            import('./components/SprintReportComponent').then(m => m.SprintReportComponent),
        mountPoint: sprintReportRouteRef,
    }),
);

export const JiraQualityPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'JiraQualityPage',
        component: () =>
            import('./components/JiraQualityComponent').then(m => m.JiraQualityComponent),
        mountPoint: qualityRouteRef,
    }),
);

export const ReleasesPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'ReleasesPage',
        component: () =>
            import('./components/ReleasesComponent').then(m => m.ReleasesComponent),
        mountPoint: releasesRouteRef,
    }),
);
export const PipelinesPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'PipelinesPage',
        component: () =>
            import('./components/PipelinesComponent').then(m => m.PipelinesComponent),
        mountPoint: pipelinesRouteRef,
    }),
);
export const LeaderBoardPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'LeaderBoardPage',
        component: () =>
            import('./components/LeaderBoardComponent').then(m => m.LeaderBoardComponent),
        mountPoint: leaderBoardRouteRef,
    }),
);
export const CapacityPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'CapacityPage',
        component: () =>
            import('./components/CapacityComponent').then(m => m.CapacityComponent),
        mountPoint: capacityRouteRef,
    }),
);

export const CalendarPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'CalendarPage',
        component: () =>
            import('./components/CalendarComponent').then(m => m.CalendarComponent),
        mountPoint: calendarRouteRef,
    }),
);

export const ITSOPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'ITSOPage',
        component: () =>
            import('./components/ITSOComponent').then(m => m.ITSOComponent),
        mountPoint: itsoRouteRef,
    }),
);

export const AIPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'AIPage',
        component: () =>
            import('./components/GenAIComponent').then(m => m.GenAIComponent),
        mountPoint: genAIRouteRef,
    }),
);

export const ITSOAdminPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'ITSOAdminPage',
        component: () =>
            import('./components/ITSOAdminPage').then(m => m.ITSOAdminComponent),
        mountPoint: itsoAdminRouteRef,
    }),
);

export const AppPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'AppPage',
        component: () =>
            import('./components/ApplicationsComponent').then(m => m.ApplicationsComponent),
        mountPoint: appRouteRef,
    }),
);
export const SquadStructurePage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'SquadStructurePage',
        component: () =>
            import('./components/SquadStructureComponent').then(m => m.SquadStructureComponent),
        mountPoint: squadStructureRouteRef,
    }),
);
export const EngageJourneyPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'EngageJourneyPage',
        component: () =>
            import('./components/PlanComponent').then(m => m.EngageJourneyComponent),
        mountPoint: engageJourneyRouteRef,
    }),
);

export const ROCPlanPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'ROCPlanPage',
        component: () =>
            import('./components/PlanComponent').then(m => m.ROCPlanComponent),
        mountPoint: rocPlanRouteRef,
    }),
);
export const PIPlanPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'PIPlanPage',
        component: () =>
            import('./components/PlanComponent').then(m => m.PIPlanningComponent),
        mountPoint: piRouteRef,
    }),
);
export const OnboardingPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'OnboardingPage',
        component: () =>
            import('./components/OnboardingComponent').then(m => m.OnboardingComponent),
        mountPoint: onboardingRouteRef,
    }),
);
export const TestingPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'TestingPage',
        component: () =>
            import('./components/TestingComponent').then(m => m.TestingComponent),
        mountPoint: testingRouteRef,
    }),
);
export const CertificatePage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'CertificatePage',
        component: () =>
            import('./components/CertificateComponent').then(m => m.CertificateComponent),
        mountPoint: certificateRouteRef,
    }),
);
export const AboutPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: 'AboutPage',
        component: () =>
            import('./components/AboutComponent').then(m => m.AboutComponent),
        mountPoint: aboutRouteRef,
    }),
);
export const VulnerabilitiesPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: "VulnerabilitiesPage",
        component: () =>
            import('./components/VulnerabilitiesComponent').then(m => m.VulnerabilitiesComponent),
        mountPoint: vulnerabilitiesRouteRef
    })
)
export const RFCPage = jbJiraPlugin.provide(
    createRoutableExtension({
        name: "RFCPage",
        component: () =>
            import('./components/RFCComponent').then(m => m.RFCComponent),
        mountPoint: rFCComponent
    })
)