import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#ffffff',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="100px" height="20px"
    >
      <g className={classes.path}>
        <path className={classes.path}
              d="M0.246739514,0 L0.246739514,0.669721537 L1.65667959,0.669721537 L1.65667959,15.5093409 C1.58618259,17.4480085 1.33944307,18.6464575 0.317236517,18.963694 C0.281988016,18.963694 0,19.034191 0,19.034191 L0,19.6686641 C0,19.6686641 0.634473035,19.7039126 1.26894607,19.5629186 C3.13711667,19.1751851 4.15932323,17.765245 4.19457173,15.5798379 L4.19457173,0 L0.246739514,0 Z"
              id="Path" />
        <path className={classes.path}
              d="M17.3070145,14.8396193 L17.3070145,4.82904477 L13.5706732,4.82904477 L13.5706732,5.4987663 L14.9806133,5.4987663 L14.9806133,11.0327811 C14.9806133,12.0549877 14.6986253,13.0066972 14.2403948,13.7116673 C13.7116673,14.4871343 12.9362002,14.8748678 12.0197392,14.8748678 C11.5262601,14.8748678 11.1385266,14.7338738 10.8917871,14.4871343 C10.4335566,14.0289038 10.3630596,13.2181882 10.3630596,12.5837152 L10.3630596,4.82904477 L6.87345788,4.82904477 L6.87345788,5.4987663 L8.03665844,5.4987663 L8.03665844,12.6542122 C8.03665844,13.7116673 8.28339796,14.4518858 8.77687698,14.9806133 C9.30560451,15.5093409 10.1163201,15.7560804 11.2442721,15.7560804 C12.9362002,15.7560804 14.2403948,14.9806133 14.9453648,13.5706732 L14.9453648,15.5093409 L18.7874515,15.5093409 L18.7874515,14.8396193 L17.3070145,14.8396193 Z"
              id="Path" />
        <polygon className={classes.path} id="Path"
                 points="24.2509693 14.8396193 24.2509693 0 20.6203736 0 20.6203736 0.669721537 21.9245682 0.669721537 21.9245682 14.8396193 20.6203736 14.8396193 20.6203736 15.5093409 25.7666549 15.5093409 25.7666549 14.8396193" />
        <polygon className={classes.path} id="Path"
                 points="31.2654212 14.8396193 31.2654212 4.82904477 27.6348255 4.82904477 27.6348255 5.4987663 28.9390201 5.4987663 28.9390201 14.8396193 27.6348255 14.8396193 27.6348255 15.5093409 32.7811068 15.5093409 32.7811068 14.8396193" />
        <circle className={classes.path} id="Oval" cx="30.1022207" cy="1.55093409" r="1.37469158" />
        <path className={classes.path}
              d="M44.6951005,14.8396193 L44.6951005,4.82904477 L40.9587593,4.82904477 L40.9587593,5.4987663 L42.3686993,5.4987663 L42.3686993,11.0327811 C42.3686993,12.0549877 42.0867113,13.0066972 41.6284808,13.7116673 C41.0997533,14.4871343 40.3242862,14.8748678 39.4078252,14.8748678 C38.9143461,14.8748678 38.5266126,14.7338738 38.2798731,14.4871343 C37.8216426,14.0289038 37.7511456,13.2181882 37.7511456,12.5837152 L37.7511456,4.82904477 L34.2615439,4.82904477 L34.2615439,5.4987663 L35.4247444,5.4987663 L35.4247444,12.6542122 C35.4247444,13.7116673 35.671484,14.4518858 36.164963,14.9806133 C36.6936905,15.5093409 37.5044061,15.7560804 38.6323581,15.7560804 C40.3242862,15.7560804 41.6284808,14.9806133 42.3334508,13.5706732 L42.3334508,15.5093409 L46.1755375,15.5093409 L46.1755375,14.8396193 L44.6951005,14.8396193 Z"
              id="Path" />
        <path className={classes.path}
              d="M54.917166,7.68417342 L54.917166,5.25202679 C53.6129714,4.82904477 52.8375044,4.58230525 51.7448008,4.58230525 C50.0176243,4.58230525 48.2551992,5.60451181 48.2551992,7.86041593 C48.2551992,9.86958054 49.9823757,10.5040536 51.4628128,11.0680296 C52.7670074,11.5615086 54.000705,12.0197392 54.000705,13.2886852 C54.000705,14.4871343 52.8375044,15.1216073 51.7095523,15.1216073 C50.0881213,15.1216073 48.9954177,14.0994008 48.7486782,12.2664787 L47.9379626,12.2664787 L47.9379626,15.0863588 C49.3479027,15.5798379 50.4406063,15.7560804 51.6743038,15.7560804 C52.9432499,15.7560804 54.1769475,15.2978498 54.917166,14.5223828 C55.4811421,13.9584068 55.7983786,13.2181882 55.7983786,12.4074727 C55.7983786,9.86958054 53.930208,9.23510751 52.2735284,8.70637998 C51.0045823,8.28339796 49.9118787,7.89566443 49.9118787,6.76771237 C49.9118787,6.37997885 50.0176243,6.06274233 50.2643638,5.81600282 C50.5816003,5.4987663 51.0398308,5.35777229 51.6390553,5.35777229 C52.3087769,5.35777229 52.8727529,5.5340148 53.2252379,5.85125132 C54.000705,6.52097286 54.1769475,7.68417342 54.1769475,7.68417342 L54.917166,7.68417342 L54.917166,7.68417342 Z"
              id="Path" />
        <path className={classes.path}
              d="M71.4134649,0 C72.8586535,0 73.9161086,0.317236517 74.5858301,0.986958054 C75.1498061,1.55093409 75.4317941,2.39689813 75.4317941,3.5600987 C75.4317941,5.3930208 74.3743391,6.55622136 72.259429,7.04970039 C74.4448361,7.3316884 76.5949947,8.10715545 76.5949947,11.3147691 C76.5949947,12.6542122 76.2777582,13.6411703 75.5727882,14.3108918 C74.7620726,15.1216073 73.4931265,15.4740924 71.5897074,15.4740924 L64.540007,15.4740924 L64.540007,14.8043708 L66.0204441,14.8043708 L66.0204441,0.669721537 L64.540007,0.669721537 L64.540007,0 L71.4134649,0 Z M70.9552344,7.43743391 L68.5935848,7.43743391 L68.5935848,14.8396193 L70.6027494,14.8396193 C73.4931265,14.8396193 73.9161086,12.9714487 73.9161086,11.1385266 C73.9161086,9.72858654 73.598872,8.77687698 72.9996475,8.14240395 C72.5061685,7.64892492 71.836447,7.43743391 70.9552344,7.43743391 Z M70.5322524,0.669721537 L68.5935848,0.669721537 L68.5935848,6.66196687 L70.3560099,6.66196687 C72.118435,6.66196687 72.752908,5.88649982 72.752908,3.84208671 C72.752908,2.99612266 72.6471625,1.8329221 72.0126895,1.19844907 C71.6602044,0.845964047 71.1667254,0.669721537 70.5322524,0.669721537 Z"
              id="Combined-Shape" />
        <path className={classes.path}
              d="M83.7151921,4.61755375 C85.2661262,4.61755375 86.3235812,4.89954177 86.9580543,5.5340148 C87.4515333,6.02749383 87.6982728,6.73246387 87.6982728,7.78991893 L87.6982728,7.78991893 L87.6982728,13.2534367 C87.6982728,13.9584068 87.8040183,14.3813888 88.0155093,14.5928798 C88.2622489,14.8396193 88.6499824,14.8396193 89.3197039,14.9101163 L89.3197039,14.9101163 L89.3197039,15.5798379 L87.6277758,15.5798379 C86.4645753,15.5798379 85.6186112,15.4740924 85.2308777,14.2403948 C84.4906591,15.3683468 83.5037011,15.8265774 81.952767,15.8265774 C80.9305605,15.8265774 80.1903419,15.5798379 79.6968629,15.0863588 C79.2033839,14.5928798 78.9566443,13.8174128 78.9566443,12.6542122 C78.9566443,10.9622841 79.8026084,10.0105746 81.741276,9.55234403 L81.741276,9.55234403 L83.0454706,9.23510751 C83.9266831,9.0236165 84.5611562,8.84737399 85.3718717,8.56538597 L85.3718717,8.56538597 L85.3718717,7.71942192 C85.3718717,6.97920338 85.3013747,6.13323934 84.8783927,5.71025731 C84.6316532,5.4282693 84.2439196,5.32252379 83.7504406,5.32252379 C83.1159676,5.32252379 82.6929855,5.4282693 82.4109975,5.71025731 C82.164258,5.95699683 82.023264,6.37997885 82.023264,6.97920338 C82.023264,7.40218541 81.9175185,7.71942192 81.741276,7.89566443 C81.5650335,8.07190694 81.318294,8.14240395 80.8953119,8.14240395 C80.4723299,8.14240395 80.2255904,8.07190694 80.0493479,7.89566443 C79.9083539,7.75467043 79.8378569,7.54317941 79.8378569,7.2964399 C79.8378569,4.86429327 82.5519915,4.61755375 83.7151921,4.61755375 Z M85.5128657,9.23510751 C85.1251322,9.34085301 83.3274586,9.86958054 83.3627071,9.83433204 C82.1290095,10.2220656 81.7060275,10.6802961 81.7060275,12.5484667 C81.7060275,13.6411703 81.88227,14.3108918 82.234755,14.6633768 C82.446246,14.8748678 82.6929855,14.9806133 83.0454706,14.9806133 C84.8431442,14.9806133 85.5128657,12.6542122 85.5128657,11.2090236 L85.5128657,11.2090236 Z"
              id="Combined-Shape" />
        <circle className={classes.path} id="Oval" cx="81.741276" cy="1.55093409" r="1.37469158" />
        <circle className={classes.path} id="Oval" cx="86.0768417" cy="1.55093409" r="1.37469158" />
        <path className={classes.path}
              d="M99.506521,6.83820938 C98.5195629,6.66196687 97.9555869,6.69721537 97.5678534,6.76771237 C95.6291858,7.01445189 94.6774762,9.0941135 94.6774762,11.1385266 L94.6774762,14.8396193 L96.0874163,14.8396193 L96.0874163,15.5093409 L90.941135,15.5093409 L90.941135,14.8748678 L92.3510751,14.8748678 L92.3510751,5.4987663 L90.8353895,5.4987663 L90.8353895,4.82904477 L94.6774762,4.82904477 L94.6774762,8.45964047 C95.8054283,6.06274233 97.0743743,4.75854776 98.3785689,4.79379626 C99.1187875,4.79379626 99.6827635,4.93479027 100,5.04053578 L99.506521,6.83820938 Z"
              id="Path" />
      </g>
    </svg>
  );
};

export default LogoFull;
